import React, { useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { getRandomInt } from 'utils';

import { WindowTypes } from '../constants/WindowTypes';

import { Console } from './partials/Console';
import { FolderWindowContent } from './partials/FolderWindowContent';
import { HTMLWindowContent } from './partials/HTMLWindowContent';
import { MessageWindowContent } from './partials/MessageWindowContent';
import { MessengerChatWindowContent } from './partials/MessengerChatWindowContent';
import { MessengerMainWindowContent } from './partials/MessengerMainWindowContent';
import { YouTubeWindowContent } from './partials/YouTubeWindowContent';

import { setDraggingActive, setDragRelativePosition, setResizingActive, useDesktop } from 'redux/desktop';
import { removeWindow, setActiveWindow, setWindowDragging, setWindowOpen, setWindowPosition, setWindowResizing, setWindowSize, useWindows } from 'redux/windows';

import './Window.css';


export const Window = (props: { id: number }) => {
    const activeWindow = useWindows().activeWindow;
    const children = useWindows().windows.filter(window => window.parent === props.id);
    const isDraggingActive = useDesktop().isDraggingActive;
    const isResizingActive = useDesktop().isResizingActive;
    const viewSize = useDesktop().viewSize;
    const window = useWindows().windows.find(window => window.id === props.id);
    const windows = useWindows().windows;

    useEffect(() => {
        if (viewSize.width && viewSize.height) {
            if (window?.attributes.isOpen) {
                if (window.attributes.size === undefined) {
                    setWindowSize({
                        windowId: window.id,
                        size: {
                            height: 25,
                            width: 300
                        }
                    });
                } else {
                    if (window.attributes.position === undefined) {
                        setWindowPosition({
                            windowId: window.id,
                            position: {
                                x: getRandomInt(0, (viewSize.width - window.attributes.size.width)),
                                y: getRandomInt(25, (viewSize.height - window.attributes.size.height))
                            }
                        });
                    } else {
                        if (window.attributes.position.x < 0) {
                            setWindowPosition({
                                windowId: window.id,
                                position: {
                                    x: 0,
                                    y: window.attributes.position.y
                                }
                            })
                        }

                        if (window.attributes.position.y < 25) {
                            setWindowPosition({
                                windowId: window.id,
                                position: {
                                    x: window.attributes.position.x,
                                    y: 25
                                }
                            })
                        }

                        if (window.attributes.position.x + window.attributes.size.width > viewSize.width) {
                            setWindowSize({
                                windowId: window.id,
                                size: {
                                    height: window.attributes.size.height,
                                    width: viewSize.width - window.attributes.position.x
                                }
                            });

                            if (viewSize.width - window.attributes.position.x <= 200) {
                                setWindowPosition({
                                    windowId: window.id,
                                    position: {
                                        x: 0,
                                        y: window.attributes.position.y
                                    }
                                });
                            }
                        }

                        if (window.attributes.position.y + window.attributes.size.height > viewSize.height) {
                            setWindowSize({
                                windowId: window.id,
                                size: {
                                    height: viewSize.height - window.attributes.position.y,
                                    width: window.attributes.size.width
                                }
                            });

                            if (viewSize.height - window.attributes.position.y <= 200) {
                                setWindowPosition({
                                    windowId: window.id,
                                    position: {
                                        x: window.attributes.position.x,
                                        y: 25,
                                    }
                                });
                            }
                        }
                    }

                    if (window.attributes.size.height < 25) {
                        setWindowSize({
                            windowId: window.id,
                            size: {
                                height: 25,
                                width: window.attributes.size.width
                            }
                        });
                    }

                    if (window.attributes.size.width < 200) {
                        setWindowSize({
                            windowId: window.id,
                            size: {
                                height: window.attributes.size.height,
                                width: 200
                            }
                        });
                    }
                }
            }
        }
    }, [children, viewSize, window?.id, window?.attributes.isOpen, window?.attributes.position, window?.attributes.size]);

    const getTranslation = (key: string) => {
        if (window !== undefined && window !== null &&
            window.attributes.translations !== undefined && window.attributes.translations !== null) {
            return window.attributes.translations.find(translation => translation.key === key)?.value
        }
    }

    const onCloseButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (window !== undefined && window !== null) {
            if (window.parent !== undefined && window.parent !== null) {
                const parentWindow = windows.find(w => w.id === window.parent);

                if (parentWindow !== undefined && parentWindow !== null) {
                    if (parentWindow.attributes.isOpen && window.attributes.typeId !== WindowTypes.WINDOW_TYPE_IM_CHAT) {
                        setWindowOpen({
                            windowId: window.id,
                            isOpen: false
                        });
                    } else {
                        removeWindow(window.id);
                    }
                } else {
                    removeWindow(window.id);
                }
            } else {
                setWindowOpen({
                    windowId: window.id,
                    isOpen: false
                });
            }

            children.forEach(child => {
                if (!child.attributes.isOpen) {
                    removeWindow(child.id);
                }
            })
        }
    }

    const onTitleMouseDown = (e: React.MouseEvent) => {
        if (window !== null && window !== undefined && window.attributes.isDraggable && !isDraggingActive) {
            setDraggingActive(true);
            setDragRelativePosition({
                x: e.pageX - window.attributes.position.x,
                y: e.pageY - window.attributes.position.y
            });
            setWindowDragging({
                windowId: window?.id,
                isDragging: true
            });
        }
    }

    const onTitleTouchStart = (e: React.TouchEvent) => {
        if (window !== null && window !== undefined && window.attributes.isDraggable && !isDraggingActive) {

            setDraggingActive(true);
            setDragRelativePosition({
                x: e.touches[0].pageX - window.attributes.position.x,
                y: e.touches[0].pageY - window.attributes.position.y
            });
            setWindowDragging({
                windowId: window?.id,
                isDragging: true
            });
        }
    }

    const onWindowMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
        if (window !== null && window !== undefined) {
            if (activeWindow !== window.id) {
                setActiveWindow(window.id);
            }
        }
    }

    const onResizeMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
        if (window !== null && window !== undefined) {
            if (window.attributes.isResizable && !isResizingActive) {
                setResizingActive(true);
                setWindowResizing({
                    windowId: window.id,
                    isResizing: true
                });
            }
        }
    }

    return window !== undefined && window !== null && window.attributes.position !== undefined && window.attributes.isOpen ? (
        <div
            className='window'
            onMouseDown={onWindowMouseDown}
            style={{
                height: window.attributes.size.height,
                left: window.attributes.position.x,
                top: window.attributes.position.y,
                width: window.attributes.size.width,
                zIndex: activeWindow === window.id ? 1 : 0
            }}
            onTouchStart={onWindowMouseDown}
        >
            {window.attributes.isResizable ?
                <div
                    className='resize-handler'
                    onMouseDown={onResizeMouseDown}
                    onTouchStart={onResizeMouseDown}
                    style={{ zIndex: activeWindow === window.id ? 1 : 0 }}
                ></div> : ''
            }
            <div className='header'>
                <div
                    className='title'
                    onMouseDown={onTitleMouseDown}
                    onTouchStart={onTitleTouchStart}
                >
                    <div className='name'>
                        {window.name}
                    </div>

                    <div className='paint'></div>
                </div>

                <div className='buttons'>
                    {getTranslation('WINDOW_DESCRIPTION_BODY') ?
                        <OverlayTrigger
                            trigger='click'
                            placement='top'
                            overlay={
                                <Popover id='help'>
                                    <Popover.Header as='h3'>{getTranslation('WINDOW_DESCRIPTION_HEADER')}</Popover.Header>
                                    <Popover.Body>
                                        {getTranslation('WINDOW_DESCRIPTION_BODY')}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <span
                                className='help'
                            ></span>
                        </OverlayTrigger>
                        : ''
                    }
                    <span
                        className='close'
                        onClick={onCloseButtonClick}
                    ></span>
                </div>
            </div>

            <div className='content'>
                {window.attributes.typeId === WindowTypes.WINDOW_TYPE_FOLDER ? <FolderWindowContent windowId={window.id} /> : ''}
                {window.attributes.typeId === WindowTypes.WINDOW_TYPE_HTML ? <HTMLWindowContent windowId={window.id} /> : ''}
                {window.attributes.typeId === WindowTypes.WINDOW_TYPE_CONSOLE ? <Console isWindow={true} windowId={window.id} /> : ''}
                {window.attributes.typeId === WindowTypes.WINDOW_TYPE_YOUTUBE ? <YouTubeWindowContent windowId={window.id} /> : ''}
                {window.attributes.typeId === WindowTypes.WINDOW_TYPE_IM_MAIN ? <MessengerMainWindowContent windowId={window.id} /> : ''}
                {window.attributes.typeId === WindowTypes.WINDOW_TYPE_IM_CHAT ? <MessengerChatWindowContent windowId={window.id} /> : ''}
                {window.attributes.typeId === WindowTypes.WINDOW_TYPE_MESSAGE ? <MessageWindowContent windowId={window.id} /> : ''}
            </div>
        </div>
    ) : null
}