import { createReduxModule } from 'hooks-for-redux';

import { ComputerStages } from '../constants/ComputerStages';


interface ComputerState {
	language: string,
	stage: number
}

const initialState: ComputerState = {
	language: '',
	stage: ComputerStages.Console
}


export const [useComputer, { setComputerLanguage, setComputerStage }] = createReduxModule(
	'computer',
	initialState,
	{
		setComputerLanguage: (state, language) => {
			return { ...state, language: language }
		},
		setComputerStage: (state, stage) => {
			return { ...state, stage: stage }
		},
	}
)