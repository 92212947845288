import { createReduxModule } from 'hooks-for-redux';


interface IconState {
    activeIconId: number,
}

const initialState: IconState = {
    activeIconId: 0,
}


export const [useIcons, { setActiveIconId }] = createReduxModule(
    'icons',
    initialState,
    {
        setActiveIconId: (state, iconId) => {
            return {
                ...state, activeIconId: iconId
            }
        }
    }
)