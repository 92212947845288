import { getWindowHTMLContent } from 'api/windows';
import React, { useEffect, useState } from 'react';

import { useWindows } from 'redux/windows';


export const HTMLWindowContent = (props: { windowId: number }) => {
    const [renderedContent, setRenderedContent] = useState('');
    const window = useWindows().windows.find(window => window.id === props.windowId);

    useEffect(() => {
        const renderedScript = document.createElement('script');

        if (window?.id! > 0 && window?.attributes.isOpen && window?.attributes.renderURL !== undefined) {
            getWindowHTMLContent(window?.attributes.renderURL).then(data => {
                if (data.indexOf('<script>') !== -1) {
                    const script = data.substring(data.indexOf('<script>') + 8, data.indexOf('</script>'));
                    
                    renderedScript.innerText = script;

                    document.head.appendChild(renderedScript);
                    data.replace(script, '');
                }
                
                setRenderedContent(data);
            });
        }

        return () => {
            if (renderedScript !== undefined && renderedScript !== null) {
                document.head.removeChild(renderedScript);
            }
        };
    }, [window?.id, window?.attributes.isOpen, window?.attributes.renderURL]);

    return window !== undefined && window !== null && window?.attributes.renderURL !== undefined ? (
        <div 
            className='htmlContent' 
            dangerouslySetInnerHTML={{ __html: renderedContent }}
            style={{
                height: window.attributes.size.height - 25
            }}
        >
        </div>
    ) : null;
}