import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay, faForward, faBackward } from '@fortawesome/free-solid-svg-icons'
import YouTube from 'react-youtube';
import { YouTubePlayer } from 'youtube-player/dist/types';

import { useDesktop } from 'redux/desktop';
import { useWindows } from 'redux/windows';
import { getRandomInt } from 'utils';
import { YouTubeWindowVideo } from 'interfaces/YouTubeWindowVideo';
import { getWindowVideos } from 'api/windows';


export const YouTubeWindowContent = (props: { windowId: number }) => {
    const volume = useDesktop().volume;
    const window = useWindows().windows.find(window => window.id === props.windowId);

    const [currentVideo, setCurrentVideo] = useState<number>(0);
    const [currentVideoSubtitles, setCurrentVideoSubtitles] = useState<string>('');
    const [currentVideoTime, setCurrentVideoTime] = useState<number>(0);
    const [currentVideoTitle, setCurrentVideoTitle] = useState<string>('');
    const [isActive, setActive] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(true);
    const [player, setPlayer] = useState<YouTubePlayer>();
    const [playedCache, setPlayedCache] = useState<Array<number>>([]);
    const [videoId, setVideoId] = useState<string>('');
    const [videos, setVideos] = useState<Array<YouTubeWindowVideo>>([]);

    const onBackwardClick = (event: React.MouseEvent) => {
        const previousVideo = playedCache.pop();

        if (previousVideo !== undefined && previousVideo !== null &&
            window !== undefined && window !== null &&
            videos.length > 0 && videos !== null) {

            changeVideo(previousVideo === currentVideo ? getRandomInt(0, videos.length - 1) : previousVideo, false);
        }
    }

    const changeVideo = (videoIndex: number, isCacheReset: boolean) => {
        if (window !== undefined && window !== null && videos.length > 0) {
            setVideoId(videos[videoIndex].video_id);
            setCurrentVideo(videoIndex);
            setCurrentVideoTime(0);
            setCurrentVideoTitle(videos[videoIndex].title);
            setCurrentVideoSubtitles('');

            if (isCacheReset)
                setPlayedCache([videoIndex]);
            else
                setPlayedCache([...playedCache, videoIndex]);
        }
    }

    const getTranslation = (key: string) => {
        if (window !== undefined && window !== null &&
            window.attributes.translations !== undefined && window.attributes.translations !== null) {
            return window.attributes.translations.find(translation => translation.key === key)?.value
        }
    }

    const onForwardClick = (event: React.MouseEvent) => {
        if (window !== undefined && window !== null &&
            videos.length > 0 && videos !== null && videos.length > 1) {
            let isPlaylistEnded = false;

            if (playedCache.length === videos.length)
                isPlaylistEnded = true;


            let nextVideo = getRandomInt(0, videos.length - 1);

            if (!isPlaylistEnded) {
                while (playedCache.indexOf(nextVideo) !== -1) {
                    nextVideo = getRandomInt(0, videos.length - 1);
                }
            }

            changeVideo(nextVideo, isPlaylistEnded);
            setIsPaused(false);
        }
    }

    const onStartPauseClick = (event: React.MouseEvent) => {
        if (player !== undefined && player !== null) {
            setIsPaused(!isPaused);

            if (!isPaused) {
                player.pauseVideo();
            } else {
                player.playVideo();
            }
        }
    }

    const onVideoReady = (event: { target: YouTubePlayer }) => {
        setPlayer(event.target);

        if (window !== undefined && window !== null && videos.length > 0) {
            const randomVideoIndex = getRandomInt(0, videos.length - 1);

            changeVideo(randomVideoIndex, false);
            setActive(true);
            
            if (!isPaused) {
                event.target.pauseVideo();
            } else {
                event.target.playVideo();
            }
        }
    }

    const onVideoStateChange = (event: { data: number }) => {
        if (!event.data) {
            if (videos.length > 0 && videos.length > 1) {
                let isPlaylistEnded = false;

                if (playedCache.length === videos.length)
                    isPlaylistEnded = true;

                let nextVideo = getRandomInt(0, videos.length - 1);

                if (!isPlaylistEnded) {
                    while (playedCache.indexOf(nextVideo) !== -1) {
                        nextVideo = getRandomInt(0, videos.length - 1);
                    }
                }

                changeVideo(nextVideo, isPlaylistEnded);
            }
        }
    }

    useEffect(() => {
        const subtitleInterval = setInterval(() => {
            if (player?.getCurrentTime() !== undefined) {
                setCurrentVideoTime(player.getCurrentTime());
            }
        }, 500);

        return () => {
            clearInterval(subtitleInterval);
        };
    }, [player]);

    useEffect(() => {
        if (window !== undefined && window !== null) {
            if (!videos.length) {
                getWindowVideos(window.id).then(data => {
                    setVideos(data);
                });
            }

            if (videos.length > 0 && currentVideoTime !== undefined) {
                const activeSub = videos[currentVideo].subtitles.find(sub => sub.start_time <= currentVideoTime && sub.end_time > currentVideoTime);

                if (activeSub !== undefined && activeSub !== null) {
                    setCurrentVideoSubtitles(activeSub.text);
                }

                setCurrentVideoTitle(videos[currentVideo].title);
            }

            if (player !== undefined && player !== null) {
                player.setSize(window.attributes.size.width, window.attributes.size.height - 25);
                player.setVolume(volume);
            }
        }
    }, [currentVideo, currentVideoTime, player, videos, volume, window]);

    return window !== undefined && window !== null && window.attributes.isOpen ? (
        <div className='youtube'>
            <div className='overlay'>
            </div>
            <div className='title-header'>{getTranslation('PLAYER_CURRENTLY_PLAYING')} <span style={{ float: "right" }}>{currentVideoTitle}</span></div>
            <div
                className='video'
                style={{
                    visibility: isActive ? 'visible' : 'hidden'
                }}
            >
                <YouTube
                    videoId={videoId}
                    onReady={onVideoReady}
                    onStateChange={onVideoStateChange}
                    opts={{
                        'playerVars': {
                            'autoplay': 1,
                            'controls': 0,
                            'disablekb': 1,
                            'iv_load_policy': 3,
                            'modestbranding': 1,
                            'playsinline': 1,
                            'rel': 0
                        }
                    }}
                />
            </div>
            <div className='subtitles'>{currentVideoSubtitles}</div>
            <div className='controls'>
                <button onClick={onBackwardClick}><FontAwesomeIcon icon={faBackward} /></button>
                <button onClick={onStartPauseClick}><FontAwesomeIcon icon={isPaused ? faPlay : faPause} /></button>
                <button onClick={onForwardClick}><FontAwesomeIcon icon={faForward} /></button>
            </div>
        </div>
    ) : null;
}