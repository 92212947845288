import { createReduxModule } from 'hooks-for-redux';


interface DesktopState {
	dragRelativePosition: {
		x: number,
		y: number
	},
	isDraggingActive: boolean,
	isResizingActive: boolean,
	isVolumeMuted: boolean,
	viewSize: {
		height: number,
		width: number
	}
	volume: number
}

const initialState: DesktopState = {
	dragRelativePosition: {
		x: 0,
		y: 0
	},
	isDraggingActive: false,
	isResizingActive: false,
	isVolumeMuted: false,
	viewSize: {
		height: 0,
		width: 0
	},
	volume: 100.0
}


export const [useDesktop, { playAudio, setDraggingActive, setDragRelativePosition, setResizingActive, setViewSize, setVolume, setVolumeMuted }] = createReduxModule(
	'desktop',
	initialState,
	{
		playAudio: (state, soundPath) => {
			const audio = new Audio(soundPath);
			
			audio.volume = state.volume / 100;
			
			audio.play();

			return state;
		},
		setDraggingActive: (state, isDraggingActive) => {
			return { ...state, isDraggingActive: isDraggingActive }
		},
		setDragRelativePosition: (state, { x, y }) => {
			return { ...state, dragRelativePosition: { x: x, y: y } }
		},
		setResizingActive: (state, isResizingActive) => {
			return { ...state, isResizingActive: isResizingActive }
		},
		setViewSize: (state, viewSize) => {
			return { ...state, viewSize: viewSize }
		},
		setVolume: (state, volume) => {
			return { ...state, volume: volume }
		},
		setVolumeMuted: (state, isVolumeMuted) => {
			return { ...state, isVolumeMuted: isVolumeMuted }
		}
	}
)