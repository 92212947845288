import ReactDOM from 'react-dom'
import { Provider } from 'hooks-for-redux'



const render = () => {
    const { Main } = require('./components/Main')

    ReactDOM.render(
        <Provider><Main /></Provider>,
        document.getElementById('root')
    )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./components/Main', render)
}
