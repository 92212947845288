import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { MessageTypes } from 'constants/MessageTypes';

import { useWindows } from 'redux/windows';


export const MessageWindowContent = (props: { windowId: number }) => {
    const window = useWindows().windows.find(window => window.id === props.windowId);

    return window !== undefined && window !== null && window.attributes.messageString !== undefined ? (
        <div className='message'>
            <div className='icon'>
                {window.attributes.messageType === MessageTypes.MESSAGE_TYPE_ERROR ?
                    <span className='error'>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                    : ''
                }
                {window.attributes.messageType === MessageTypes.MESSAGE_TYPE_INFO ?
                    <span className='info'>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    : ''
                }
            </div>
            <div className='string'>{window.attributes.messageString}</div>
        </div>
    ) : null;
}