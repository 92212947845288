import React, { useEffect, useState } from 'react';

import { getConsoleCommand, getStartupMessages } from 'api/computer';

import { setComputerStage, useComputer } from 'redux/computer';
import { addWindow, removeWindow, useWindows } from 'redux/windows';

import { ComputerStages } from 'constants/ComputerStages';
import { StartupMessage } from '../../interfaces/StartupMessage';



export const Console = (props: { isWindow: boolean, windowId?: number }) => {
    const activeWindow = useWindows().activeWindow;
    const language = useComputer().language;
    const [cursorVisible, setCursorVisible] = useState<boolean>(false);
    const [history, setHistory] = useState<string>('');
    const [currentLine, setCurrentLine] = useState<string>('');
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [isTypingAllowed, setIsTypingAllowed] = useState<boolean>(false);
    const [startupMessages, setStartupMessages] = useState<Array<StartupMessage>>([]);
    const [stage, setStage] = useState<number>(0);
    //const w = useWindows().windows.find(window => window.id === props.windowId);

    useEffect(() => {
        if (props.isWindow) {
            setCursorVisible(true);
            setIsTypingAllowed(true);
        } else {
            if (language !== '') {
                try {
                    getStartupMessages(language).then(data => {
                        setStartupMessages(data);
                    });
                } catch (error) {
                    setHistory('NETWORK: Network error occured.');
                }
            }
        }
    }, [language, props.isWindow]);

    useEffect(() => {
        if (props.isWindow) {
            const validCharacters = [
                'q', 'w', 'e', 'r', 't', 'y', 'u', 'ı', 'o', 'p', 'ğ', 'ü', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ş', 'i', ',', 'z',
                'x', 'c', 'v', 'b', 'n', 'm', 'ö', 'ç', '.', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '-', '_', ':', ' ', '?',
                '!', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'Ğ', 'Ü', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ş', 'I', 'Z',
                'X', 'C', 'V', 'B', 'N', 'M', 'Ö', 'Ç',
            ];
            const userIdentifier = 'anonymous@calabre.si: ';

            const keyDownHandler = (event: KeyboardEvent) => {
                if (activeWindow === props.windowId && isTypingAllowed) {
                    if (event.key === 'Backspace') {
                        if (currentLine.length > 0) {
                            setCurrentLine(currentLine.substr(0, currentLine.length - 1));
                        }
                    } else if (event.key === 'Enter') {
                        if (currentLine !== '') {
                            getConsoleCommand(currentLine).then(data => {
                                const response = data.response !== undefined ? data.response : '';

                                if (response.startsWith('/')) {
                                    const windowData = JSON.parse(Buffer.from(data.response.substring(1), 'base64').toString('utf-8'));

                                    removeWindow(windowData.id);
                                    addWindow(windowData);
                                } else {
                                    setHistory(history + currentLine + '\\n' + response + userIdentifier);
                                }

                                setCurrentLine('');
                            });
                        }
                    } else if (validCharacters.indexOf(event.key) > -1) {
                        setCurrentLine(currentLine + event.key);
                    }
                }
            }

            const keyUpHandler = (event: KeyboardEvent) => {

            }

            if (!history.length) {
                setHistory(userIdentifier);
            }

            window.addEventListener("keydown", keyDownHandler);
            window.addEventListener("keyup", keyUpHandler);

            return () => {
                window.removeEventListener("keydown", keyDownHandler);
                window.removeEventListener("keyup", keyUpHandler);
            };
        }
    }, [activeWindow, currentLine, history, isTypingAllowed, props.isWindow, props.windowId]);

    useEffect(() => {
        if (!props.isWindow) {
            if (startupMessages.length > 0) {
                if (!isTyping && !stage) {
                    setIsTyping(true);
                } else {
                    if (stage <= startupMessages.length - 1) {
                        setTimeout(() => {
                            setHistory(h => h + ' ' + startupMessages[stage].message);
                            setStage(s => s + 1);

                            if (startupMessages[stage].sound !== null && startupMessages[stage].sound !== undefined) {
                                const sound = new Audio(startupMessages[stage].sound);

                                sound.play();
                            }
                        }, startupMessages[stage].timeout)
                    } else {
                        setComputerStage(ComputerStages.Desktop);
                    }
                }
            }
        }
    }, [isTyping, props.isWindow, stage, startupMessages])

    return (
        <div className='console'>
            <span dangerouslySetInnerHTML={{ __html: history.replaceAll('\\n', '<br />') }}></span>
            <span dangerouslySetInnerHTML={{ __html: currentLine.replaceAll('\\n', '<br />') }}></span>
            <span
                className='blinking-cursor'
                style={{
                    display: cursorVisible && !isTyping ? 'inline' : 'none'
                }}
            >|</span>
        </div>
    )
}