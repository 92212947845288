import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import Slider from 'react-rangeslider'

import { setVolume, setVolumeMuted, useDesktop } from 'redux/desktop';


export const Volume = () => {
    const isVolumeMuted = useDesktop().isVolumeMuted;
    const volume = useDesktop().volume;
    
    const onVolumeChange = (value: number) => {
        setVolume(value);
    }

    const onVolumeIconClick = (event: React.MouseEvent) => {
        setVolumeMuted(!isVolumeMuted);

        if (!isVolumeMuted) {
            setVolume(0);
        } else {
            setVolume(100);
        }
    }

    return (
        <div className='volume'>
            <FontAwesomeIcon icon={isVolumeMuted ? faVolumeMute : faVolumeUp} onClick={onVolumeIconClick} />
            <div className='slider'>
                <Slider
                    value={volume}
                    orientation="horizontal"
                    onChange={onVolumeChange}
                />
            </div>
        </div>
    )
}