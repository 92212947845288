import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faComments, faCircle, faEnvelope, faFileSignature, faKey, faUserPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

import { playAudio } from 'redux/desktop';
import { useUser, setMessengerSocket, setMessengerToken, setUsername, setMessengerFriends, addMessengerFriend, removeMessengerFriend } from 'redux/user';
import { addWindow, addWindowChatMessage, removeWindow, removeWindowsByParent, useWindows } from 'redux/windows';

import { settings } from 'settings';

import { MessageTypes } from 'constants/MessageTypes';
import { WindowTypes } from 'constants/WindowTypes';

import messengerSound from '../../static/messenger_sound.mp3';


export const MessengerMainWindowContent = (props: { windowId: number }) => {
    const [addFriendEmail, setAddFriendEmail] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isAddingFriend, setAddingFriend] = useState<boolean>(false);
    const [isChangingName, setChangingName] = useState<boolean>(false);
    const [newUsername, setNewUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [selectedFriend, setSelectedFriend] = useState<number>();
    const messengerFriends = useUser().messengerFriends;
    const messengerSocket = useUser().messengerSocket;
    const messengerToken = useUser().messengerToken;
    const username = useUser().username;
    const window = useWindows().windows.find(window => window.id === props.windowId);

    const onAcceptFriendClick = (friendId: number) => {
        if (messengerSocket !== null) {
            messengerSocket.send(JSON.stringify({
                'action': 'approve_friend',
                'data': {
                    'friend_id': friendId,
                },
                'token': messengerToken
            }));
        }
    }

    const onAddFriendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddFriendEmail(event.target.value);
    }

    const onAddFriendClick = (event: React.MouseEvent) => {
        setAddingFriend(!isAddingFriend);
    }

    const onAddFriendAddClick = (event: React.MouseEvent) => {
        if (addFriendEmail && messengerSocket !== null) {
            messengerSocket.send(JSON.stringify({
                'action': 'add_friend',
                'data': {
                    'email': addFriendEmail,
                },
                'token': messengerToken
            }));
        }
    }

    const onChangeNameClick = (event: React.MouseEvent) => {
        setChangingName(!isChangingName);
    }

    const onChangeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewUsername(event.target.value);
    }

    const onChangeNameSubmitClick = (event: React.MouseEvent) => {
        if (newUsername !== '' && messengerSocket !== null) {
            messengerSocket.send(JSON.stringify({
                'action': 'change_username',
                'data': {
                    'username': newUsername,
                },
                'token': messengerToken
            }));
            setChangingName(false);
            setNewUsername('');
        }
    }

    const onDenyFriendClick = (friendId: number) => {
        if (messengerSocket !== null) {
            messengerSocket.send(JSON.stringify({
                'action': 'deny_friend',
                'data': {
                    'friend_id': friendId,
                },
                'token': messengerToken
            }));
        }
    }

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const onFriendClick = (friendId: number) => {
        if (selectedFriend !== friendId) {
            setSelectedFriend(friendId);
        } else {
            if (messengerSocket !== null) {
                messengerSocket.send(JSON.stringify({
                    'action': 'get_chat_data',
                    'data': {
                        'friend_id': friendId
                    },
                    'token': messengerToken
                }));
            }

            setSelectedFriend(0);
        }
    }

    const onLoginClick = (event: React.MouseEvent) => {
        if (messengerSocket !== null) {
            if (email.length > 3 && password.length > 3 && email.indexOf('@') !== -1) {
                messengerSocket.send(JSON.stringify({
                    'action': 'login',
                    'data': {
                        'email': email,
                        'password': password
                    }
                }));
            }
        }
    }

    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const getTranslation = (key: string) => {
        if (window !== undefined && window !== null &&
            window.attributes.translations !== undefined && window.attributes.translations !== null) {
            return window.attributes.translations.find(translation => translation.key === key)?.value
        }
    }

    useEffect(() => {
        const createMessageWindow = (messageString: string, messageType: number) => {
            removeWindow(settings.MESSAGE_WINDOW_ID);
            addWindow({
                id: settings.MESSAGE_WINDOW_ID,
                name: messageType === MessageTypes.MESSAGE_TYPE_INFO ? getTranslation('MESSAGE_HEADER_INFO') : getTranslation('MESSAGE_HEADER_ERROR'),
                parent: window?.id,
                icon: {
                    id: 0,
                    url: ''
                },
                attributes: {
                    grid: 0,
                    isDraggable: true,
                    isDragging: false,
                    isOpen: true,
                    isResizable: false,
                    isResizing: false,
                    messageString: getTranslation(messageString),
                    messageType: messageType,
                    size: {
                        height: 100,
                        width: 350
                    },
                    typeId: WindowTypes.WINDOW_TYPE_MESSAGE
                }
            });
        }

        const getTranslation = (key: string) => {
            if (window?.attributes.translations !== undefined && window?.attributes.translations !== null) {
                return window.attributes.translations.find(translation => translation.key === key)?.value
            }
        }

        if (window?.id! > 0 && window?.attributes.isOpen) {
            if (messengerSocket === null) {
                const socket = new WebSocket(settings.MESSENGER_WEBSOCKET_URL);

                setMessengerSocket(socket);

                socket.onopen = () => {
                };

                socket.onclose = () => {
                };
            } else {
                messengerSocket.onmessage = (event) => {
                    const received_data = JSON.parse(event.data);

                    if (received_data.action === 'invalid_login_attempt') {
                        createMessageWindow('MESSENGER_INVALID_LOGIN', MessageTypes.MESSAGE_TYPE_ERROR);
                    } else if (received_data.action === 'login_success') {
                        setMessengerFriends(received_data.data.friends);
                        setMessengerToken(received_data.token);
                        setUsername(received_data.data.username);
                    } else if (received_data.action === 'add_friend_email_not_exists') {
                        createMessageWindow('MESSENGER_ADD_FRIEND_EMAIL_NOT_EXISTS', MessageTypes.MESSAGE_TYPE_ERROR);
                    } else if (received_data.action === 'add_friend_exists') {
                        createMessageWindow('MESSENGER_ADD_FRIEND_EXISTS', MessageTypes.MESSAGE_TYPE_ERROR);
                    } else if (received_data.action === 'add_friend_success') {
                        createMessageWindow('MESSENGER_ADD_FRIEND_SUCCESS', MessageTypes.MESSAGE_TYPE_INFO);
                        setAddingFriend(false);
                        setAddFriendEmail('');
                    } else if (received_data.action === 'user_changed') {
                        if (received_data.data.is_self) {
                            setUsername(received_data.data.user.username);
                        } else {
                            removeMessengerFriend(received_data.data.friend);
                            addMessengerFriend(received_data.data.friend);
                        }
                    } else if (received_data.action === 'approve_friend') {
                        removeMessengerFriend(received_data.data);
                        addMessengerFriend(received_data.data);
                    } else if (received_data.action === 'deny_friend') {
                        removeMessengerFriend(received_data.data);
                    } else if (received_data.action === 'friend_not_approved_yet') {
                        createMessageWindow('MESSENGER_FRIEND_NOT_APPROVED_YET', MessageTypes.MESSAGE_TYPE_ERROR);
                    } else if (received_data.action === 'get_chat_data') {
                        addWindow({
                            id: 1000 + received_data.data.friend.friend.id,
                            name: `${received_data.data.friend.friend.username} - Chat`,
                            parent: window?.id,
                            icon: {
                                id: 0,
                                url: ''
                            },
                            attributes: {
                                chatMessages: received_data.data.chat_messages,
                                grid: 0,
                                isDraggable: true,
                                isDragging: false,
                                isOpen: true,
                                isResizable: true,
                                isResizing: false,
                                size: {
                                    height: 300,
                                    width: 500
                                },
                                typeId: WindowTypes.WINDOW_TYPE_IM_CHAT
                            }
                        });
                    } else if (received_data.action === 'send_chat_message') {
                        addWindowChatMessage({
                            windowId: 1000 + received_data.data.friend.friend.id,
                            chatMessage: received_data.data.chat_message
                        });

                        if (received_data.data.has_sound) {
                            playAudio(messengerSound);
                        }
                    }
                }
            }

            return () => {
                if (messengerSocket !== null) {
                    messengerSocket.close();

                    setMessengerToken('');
                    setMessengerSocket(null);
                    removeWindowsByParent(window.id);
                }
            };
        }
    }, [messengerSocket, window?.id, window?.attributes.isOpen, window?.attributes.translations]);

    return window !== undefined && window !== null ? (
        <div className='messenger'>
            <div className='main'>
                {messengerToken ? (
                    <div className='fl'>
                        <div className='buttons'>
                            <Button size='sm' variant='secondary'>
                                <FontAwesomeIcon icon={faUserPlus} onClick={onAddFriendClick} />
                            </Button>
                            <Button size='sm' variant='secondary'>
                                <FontAwesomeIcon icon={faFileSignature} onClick={onChangeNameClick} />
                            </Button>
                            <div className='user'>
                                {isChangingName ?
                                    <InputGroup>
                                        <Button onClick={onChangeNameSubmitClick} size='sm'>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>
                                        <FormControl
                                            defaultValue={username}
                                            onChange={onChangeNameChange}
                                        />
                                    </InputGroup>
                                    : username
                                }
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        {isAddingFriend ?
                            <div className='add-friend'>
                                <InputGroup>
                                    <FormControl
                                        onChange={onAddFriendChange}
                                    />
                                    <Button onClick={onAddFriendAddClick} size='sm'>
                                        {getTranslation('MESSENGER_ADD_FRIEND')}
                                    </Button>
                                </InputGroup>
                            </div>
                            : ''}
                        <div
                            className='friends'
                            style={{
                                height: isAddingFriend ? 'calc(100% - 81px)' : 'calc(100% - 52px)'
                            }}
                        >
                            <div className='online'>
                                {messengerFriends.filter(friend => friend.friend.is_online).map(friend =>
                                    <div
                                        className={selectedFriend === friend.friend.id ? 'friend selected' : 'friend'}
                                        onClick={() => onFriendClick(friend.friend.id)}
                                        key={friend.friend.id}
                                    >
                                        <div className='status'><FontAwesomeIcon icon={faCircle} /></div>
                                        <div className='unread'>{friend.unread_message_count ? <Badge bg="danger">{friend.unread_message_count}</Badge> : ''}</div>
                                        <div className='name'>{friend.friend.username}</div>
                                        {!friend.is_accepted ?
                                            <div className='approval'>
                                                <Button
                                                    id={`${friend.friend.id}_approve`}
                                                    onClick={() => onAcceptFriendClick(friend.friend.id)}
                                                    size='sm'
                                                    variant='success'
                                                >
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                                <Button
                                                    id={`${friend.friend.id}_deny`}
                                                    onClick={() => onDenyFriendClick(friend.friend.id)}
                                                    size='sm'
                                                    variant='danger'
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </Button>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                )}
                            </div>
                            <div className='offline'>
                                {messengerFriends.filter(friend => !friend.friend.is_online).map(friend =>
                                    <div
                                        className={selectedFriend === friend.friend.id ? 'friend selected' : 'friend'}
                                        onClick={() => onFriendClick(friend.friend.id)}
                                        key={friend.friend.id}
                                    >
                                        <div className='status'><FontAwesomeIcon icon={faCircle} /></div>
                                        <div className='unread'>{friend.unread_message_count ? <Badge bg="danger">{friend.unread_message_count}</Badge> : ''}</div>
                                        <div className='name'>{friend.friend.username}</div>
                                        {!friend.is_accepted ?
                                            <div className='approval'>
                                                <Button
                                                    id={`${friend.friend.id}_approve`}
                                                    onClick={() => onAcceptFriendClick(friend.friend.id)}
                                                    size='sm'
                                                    variant='success'
                                                >
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                                <Button
                                                    id={`${friend.friend.id}_deny`}
                                                    onClick={() => onDenyFriendClick(friend.friend.id)}
                                                    size='sm'
                                                    variant='danger'
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </Button>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='login'>
                        <div className='icon'><FontAwesomeIcon icon={faComments} /></div>
                        <InputGroup>
                            <InputGroup.Text id='email'><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                            <FormControl
                                aria-label={getTranslation('MESSENGER_EMAIL')}
                                aria-describedby='email'
                                onChange={onEmailChange}
                                placeholder={getTranslation('MESSENGER_EMAIL')}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text id='password'><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                            <FormControl
                                aria-label={getTranslation('MESSENGER_PASSWORD')}
                                aria-describedby='password'
                                onChange={onPasswordChange}
                                placeholder={getTranslation('MESSENGER_PASSWORD')}
                                type='password'
                            />
                        </InputGroup>
                        <div className='buttons'>
                            <button onClick={onLoginClick}>{getTranslation('MESSENGER_LOGIN')}</button>
                        </div>
                        <div className='help'>
                            {getTranslation('MESSENGER_HELP_TEXT')}
                        </div>
                    </div>
                )}
            </div>
        </div >
    ) : null;
}