import { MessageTypes } from 'constants/MessageTypes';
import { WindowTypes } from 'constants/WindowTypes';
import { createReduxModule } from 'hooks-for-redux';

import { WindowData } from '../interfaces/WindowData';

import { settings } from '../settings';



interface WindowsState {
	activeWindow: number,
	windows: WindowData[]
}

const initialState: WindowsState = {
	activeWindow: -1,
	windows: settings.DEBUG ? [
		{
			id: settings.DEBUG_WINDOW_ID,
			parent: null,
			name: "debug window",
			icon: {
				id: 0,
				url: "https://static.thenounproject.com/png/83827-200.png"
			},
			attributes: {
				grid: 1,
				isDraggable: true,
				isDragging: false,
				isOpen: true,
				isResizable: true,
				isResizing: false,
				messageType: MessageTypes.MESSAGE_TYPE_INFO,
				messageString: 'TEST',
				position: {
					x: 250,
					y: 250
				},
				size: {
					height: 300,
					width: 300
				},
				typeId: WindowTypes.WINDOW_TYPE_MESSAGE
			}
		}
	] : []
}


export const [useWindows, { addWindow, addWindowChatMessage, removeWindow, removeWindowsByParent, setActiveWindow, setWindowDragging, setWindowOpen, setWindowPosition, setWindowResizing, setWindowSize }] = createReduxModule(
	'windows',
	initialState,
	{
		addWindow: (state, window) => {
			const windowIndex = state.windows.findIndex(w => w.id === window.id);

			return windowIndex === -1 ? {
				...state, windows: [...state.windows, window]
			} : { ...state }
		},
		addWindowChatMessage: (state, { windowId, chatMessage }) => {
			const windowIndex = state.windows.findIndex(window => window.id === windowId);

			return windowIndex !== -1 ? {
				...state, windows: [...state.windows.slice(0, windowIndex), {
					...state.windows[windowIndex],
					attributes: {
						...state.windows[windowIndex].attributes,
						chatMessages: [...state.windows[windowIndex].attributes.chatMessages!, chatMessage]
					}
				},
				...state.windows.slice(windowIndex + 1)]
			} : { ...state }
		},
		removeWindow: (state, windowId) => {
			const windowIndex = state.windows.findIndex(window => window.id === windowId);
			
			return windowIndex !== -1 ? {
				...state, windows: [...state.windows.slice(0, windowIndex), ...state.windows.slice(windowIndex + 1)]
			} : { ...state }
		},
		removeWindowsByParent: (state, parentId) => {
			let windows = state.windows;

			state.windows.forEach((window, index) => {
				if (window.parent === parentId) {
					windows.splice(index, 1);
				}
			})

			return {
				...state, windows: windows
			}
		},
		setActiveWindow: (state, windowId) => {
			return {
				...state, activeWindow: windowId
			}
		},
		setWindowDragging: (state, { windowId, isDragging }) => {
			const windowIndex = state.windows.findIndex(window => window.id === windowId);

			return {
				...state, windows: [...state.windows.slice(0, windowIndex), {
					...state.windows[windowIndex],
					attributes: {
						...state.windows[windowIndex].attributes,
						isDragging: isDragging
					}
				},
				...state.windows.slice(windowIndex + 1)]
			}
		},
		setWindowOpen: (state, { windowId, isOpen }) => {
			const windowIndex = state.windows.findIndex(window => window.id === windowId);

			return {
				...state, windows: [...state.windows.slice(0, windowIndex), {
					...state.windows[windowIndex],
					attributes: {
						...state.windows[windowIndex].attributes,
						isOpen: isOpen
					}
				},
				...state.windows.slice(windowIndex + 1)]
			}
		},
		setWindowPosition: (state, { windowId, position: { x, y } }) => {
			const windowIndex = state.windows.findIndex(window => window.id === windowId);

			return {
				...state, windows: [...state.windows.slice(0, windowIndex), {
					...state.windows[windowIndex],
					attributes: {
						...state.windows[windowIndex].attributes,
						position: {
							x: x,
							y: y
						}
					}
				},
				...state.windows.slice(windowIndex + 1)]
			}
		},
		setWindowResizing: (state, { windowId, isResizing }) => {
			const windowIndex = state.windows.findIndex(window => window.id === windowId);

			return {
				...state, windows: [...state.windows.slice(0, windowIndex), {
					...state.windows[windowIndex],
					attributes: {
						...state.windows[windowIndex].attributes,
						isResizing: isResizing
					}
				},
				...state.windows.slice(windowIndex + 1)]
			}
		},
		setWindowSize: (state, { windowId, size: { height, width } }) => {
			const windowIndex = state.windows.findIndex(window => window.id === windowId);

			return {
				...state, windows: [...state.windows.slice(0, windowIndex), {
					...state.windows[windowIndex],
					attributes: {
						...state.windows[windowIndex].attributes,
						size: {
							height: height,
							width: width
						}
					}
				},
				...state.windows.slice(windowIndex + 1)]
			}
		}
	}
)