import React, { useEffect, useState } from 'react';

import { useComputer } from 'redux/computer';
import { addWindow, useWindows } from 'redux/windows';

import { getWindows } from 'api/windows';

import { Icon } from '../Icon';

import { settings } from 'settings';


export const FolderWindowContent = (props: { windowId: number }) => {
    const childrenWindows = useWindows().windows.filter(window => window.parent === props.windowId);
    const language = useComputer().language;
    const window = useWindows().windows.find(window => window.id === props.windowId);
    const [gridCount, setGridCount] = useState<number>(0);

    useEffect(() => {
        if (window?.id! > 0 && window?.attributes.isOpen) {
            setGridCount(Math.floor((window.attributes.size.width / settings.DESKTOP_GRID_WIDTH) - 1));
        }
    }, [window?.id, window?.attributes.isOpen, window?.attributes.size]);

    useEffect(() => {
        if (window?.id! > 0 && window?.attributes.isOpen) {
            getWindows(language, { parent: window?.id }).then(data => {
                data.forEach(window => {
                    addWindow(window);
                })
            });
        }
    }, [language, window?.id, window?.attributes.isOpen]);

    return window !== undefined && window !== null ? (
        <div className='folder'>
            {Array.from(Array(gridCount).keys()).map((grid, index) =>
                <div
                    key={index}
                    className='grid'
                    style={{
                        margin: `0 ${((window.attributes.size.width - (settings.DESKTOP_GRID_WIDTH * gridCount)) / gridCount / 2)}px`,
                        minHeight: 10,
                        width: 75
                    }}
                >
                    {childrenWindows.map(child => child.attributes.grid === index ? <Icon key={child.id} windowId={child.id} /> : "")}
                </div>
            )}
        </div>
    ) : null;
}