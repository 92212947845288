import React, { useEffect, useState } from 'react';

import { settings } from '../settings';

import { Icon } from './Icon';
import { Window } from './Window';

import { setDraggingActive, setResizingActive, setViewSize, useDesktop } from '../redux/desktop';
import { addWindow, setWindowDragging, setWindowPosition, setWindowResizing, setWindowSize, useWindows } from 'redux/windows';

import { getWindows } from '../api/windows';

import './Desktop.css';
import { useComputer } from 'redux/computer';



export const Desktop = () => {
    const language = useComputer().language;
    const dragRelativePosition = useDesktop().dragRelativePosition;
    const draggedWindow = useWindows().windows.find(window => window.attributes.isDragging === true);
    const isDraggingActive = useDesktop().isDraggingActive;
    const isResizingActive = useDesktop().isResizingActive;
    const resizedWindow = useWindows().windows.find(window => window.attributes.isResizing === true);
    const viewSize = useDesktop().viewSize;
    const windows = useWindows().windows;
    const [grids, setGrids] = useState<Array<Array<number>>>([]);

    useEffect(() => {
        const onWindowResize = () => {
            setViewSize({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }

        if(!viewSize.height && !viewSize.width) {
            setViewSize({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }

        if (language !== '') {
            getWindows(language, { parent: null }).then(data => {
                let calculatedGrids: Array<Array<number>> = [];

                for (let i = 0; i < (window.innerWidth / settings.DESKTOP_GRID_WIDTH) - 1; i++)
                    calculatedGrids.push([]);

                data.forEach(window => {
                    addWindow(window);

                    let usedGrid = 0;

                    if (window.attributes.grid < calculatedGrids.length) {
                        usedGrid = window.attributes.grid;

                        if (usedGrid < 0) {
                            usedGrid += calculatedGrids.length
                        }
                    }

                    calculatedGrids[usedGrid].push(window.id);
                });

                setGrids(calculatedGrids);
            });
            getWindows(language, { isOpen: true }).then(data => {
                data.forEach(window => {
                    addWindow(window);
                });
            });
        }

        window.addEventListener("resize", onWindowResize);
        
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, [language, viewSize]);

    const onMouseMove = (e: React.MouseEvent) => {
        if (isDraggingActive) {
            if (draggedWindow !== null && draggedWindow !== undefined) {
                let draggedPosition = {
                    x: e.pageX - dragRelativePosition.x,
                    y: e.pageY - dragRelativePosition.y
                };

                if (draggedPosition.x < 0)
                    draggedPosition.x = 0;

                if (draggedPosition.y < 25)
                    draggedPosition.y = 25;

                if (draggedPosition.x + draggedWindow.attributes.size.width > viewSize.width)
                    draggedPosition.x = viewSize.width - draggedWindow.attributes.size.width - 2;

                if (draggedPosition.y + draggedWindow.attributes.size.height > viewSize.height)
                    draggedPosition.y = viewSize.height - draggedWindow.attributes.size.height - 3;

                setWindowPosition({
                    windowId: draggedWindow.id,
                    position: {
                        x: draggedPosition.x,
                        y: draggedPosition.y
                    }
                });
            }
        } else if (isResizingActive) {
            if (resizedWindow !== null && resizedWindow !== undefined) {
                let resizedSize = {
                    height: e.pageY - resizedWindow.attributes.position.y,
                    width: e.pageX - resizedWindow.attributes.position.x
                };

                if (resizedSize.width < 200)
                    resizedSize.width = 200;

                if (resizedSize.height < 100)
                    resizedSize.height = 100;

                setWindowSize({
                    windowId: resizedWindow.id,
                    size: {
                        height: resizedSize.height,
                        width: resizedSize.width
                    }
                });
            }
        }
    }

    const onTouchMove = (e: React.TouchEvent) => {
        if (isDraggingActive) {
            if (draggedWindow !== null && draggedWindow !== undefined) {
                let draggedPosition = {
                    x: e.touches[0].pageX - dragRelativePosition.x,
                    y: e.touches[0].pageY - dragRelativePosition.y
                };

                if (draggedPosition.x < 0)
                    draggedPosition.x = 0;

                if (draggedPosition.y < 25)
                    draggedPosition.y = 25;

                if (draggedPosition.x + draggedWindow.attributes.size.width > viewSize.width)
                    draggedPosition.x = viewSize.width - draggedWindow.attributes.size.width - 2;

                if (draggedPosition.y + draggedWindow.attributes.size.height > viewSize.height)
                    draggedPosition.y = viewSize.height - draggedWindow.attributes.size.height - 3;

                setWindowPosition({
                    windowId: draggedWindow.id,
                    position: {
                        x: draggedPosition.x,
                        y: draggedPosition.y
                    }
                });
            }
        } else if (isResizingActive) {
            if (resizedWindow !== null && resizedWindow !== undefined) {
                let resizedSize = {
                    height: e.touches[0].pageY - resizedWindow.attributes.position.y,
                    width: e.touches[0].pageX - resizedWindow.attributes.position.x
                };

                if (resizedSize.width < 200)
                    resizedSize.width = 200;

                if (resizedSize.height < 100)
                    resizedSize.height = 100;

                setWindowSize({
                    windowId: resizedWindow.id,
                    size: {
                        height: resizedSize.height,
                        width: resizedSize.width
                    }
                });
            }
        }
    }

    const onMouseUp = (e: React.MouseEvent | React.TouchEvent) => {
        if (isDraggingActive) {
            if (draggedWindow !== null && draggedWindow !== undefined) {
                setWindowDragging({
                    windowId: draggedWindow.id,
                    isDragging: false
                });
                setDraggingActive(false);
            }
        } else if (isResizingActive) {
            if (resizedWindow !== null && resizedWindow !== undefined) {
                setWindowResizing({
                    windowId: resizedWindow.id,
                    isResizing: false
                });
                setResizingActive(false);
            }
        }
    }

    return (
        <div
            className='desktop'
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onTouchMove={onTouchMove}
            onTouchEnd={onMouseUp}
            style={{
                padding: `0 ${(viewSize.width % settings.DESKTOP_GRID_WIDTH) / 4}px`
            }}
        >
            {grids.map((grid, index) =>
                <div
                    key={index}
                    className='grid'
                    style={{
                        minHeight: 10,
                        width: settings.DESKTOP_GRID_WIDTH
                    }}
                >
                    {grid.map(icon => <Icon key={icon} windowId={icon} />)}
                </div>
            )}
            {windows.map(window => <Window key={window.id} id={window.id} />)}
        </div>
    )
}