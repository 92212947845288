import React from 'react';

import { setActiveIconId, useIcons } from '../redux/icons';
import { setWindowOpen, useWindows } from 'redux/windows';

import { WindowTypes } from 'constants/WindowTypes';

import './Icon.css';


export const Icon = (props: { windowId: number }) => {
    const activeIconId = useIcons().activeIconId;
    const iconWindow = useWindows().windows.find(window => window.id === props.windowId);

    const onIconClick = () => {
        if (iconWindow !== undefined && iconWindow !== null) {
            if (activeIconId === iconWindow.id) {
                if (iconWindow.attributes.typeId === WindowTypes.WINDOW_TYPE_REDIRECT) {
                    window.open(iconWindow.attributes.redirectURL);
                } else {
                    setWindowOpen({
                        windowId: iconWindow.id,
                        isOpen: true
                    });
                }

                setActiveIconId(0);
            } else {
                setActiveIconId(iconWindow.id);
            }
        }
    }

    return iconWindow !== undefined && iconWindow !== null && iconWindow.icon !== undefined && iconWindow.icon !== null ? (
        <div
            className={'icon' + (activeIconId === iconWindow.id ? ' active' : '')}
            onClick={onIconClick}
        >
            <div className='image'>
                <img alt={iconWindow.name} src={iconWindow.icon.url} />
            </div>

            <div className='name'>
                {iconWindow.name}
            </div>
        </div>
    ) : null
}