import React from 'react';

import { Time } from './partials/Time';
import { Volume } from './partials/Volume';

import 'react-rangeslider/lib/index.css'
import './ActionBar.css';



export const ActionBar = () => {
    return (
        <div className='action-bar'>
            <div className='action-bar-left'>
                <span className='title'>calabre.si</span>
            </div>

            <div className='action-bar-right'>
                <Time />
                <Volume />
            </div>
        </div>
    )
}