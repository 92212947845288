import axios from 'axios';

import { WindowData } from 'interfaces/WindowData';
import { YouTubeWindowVideo } from 'interfaces/YouTubeWindowVideo';

import { settings } from '../settings';


export const getWindows = async (language: string, args: object): Promise<Array<WindowData>> => {
    let url = `${settings.API_BASE_URL}/desktop/windows?language=${language}`;

    for (const [key, value] of Object.entries(args)) {
        if (key === 'parent') {
            if (value === null) {
                url += '&orphan=True';
            } else {
                url += `&parent=${value}`;
            }
        } else if (key === 'isOpen') {
            if (value !== null) {
                url += `&is_open=${value}`;
            }
        }
    }

    const response = await axios.get(url);

    return response.data;
}


export const getWindowHTMLContent = async (renderURL: string): Promise<string> => {
    const response = await axios.get(renderURL);

    return response.data;
}


export const getWindowVideos = async (windowId: number): Promise<Array<YouTubeWindowVideo>> => {
    const url = `${settings.API_BASE_URL}/desktop/windows/${windowId}/videos`
    const response = await axios.get(url);

    return response.data;
}