import React, { useEffect } from 'react';

import { setComputerLanguage, useComputer } from '../redux/computer';

// Components
import { ActionBar } from './ActionBar';
import { Console } from './partials/Console';
import { Desktop } from './Desktop';

import { ComputerStages } from 'constants/ComputerStages';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Main.css';


export const Main: React.FC = () => {
	const stage = useComputer().stage;

	useEffect(() => {
		const language = window.navigator.language.substr(0, 2);

		if (language !== 'tr') {
			setComputerLanguage('en');
		} else {
			setComputerLanguage('tr');
		}

		window.oncontextmenu = function (e) {
			e.preventDefault();
		};
	}, [])

	return (
		<div className="main">
			{stage === ComputerStages.Console ? <Console isWindow={false} /> : (
				<div style={{
					height: '100%'
				}}>
					<ActionBar />
					<Desktop />
				</div>
			)}
		</div>
	)
};
