import React, { useEffect, useState } from 'react';



export const Time = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        setInterval(() => {
            setTime(new Date());
        }, 60000);
    }, []);

    return (
        <div className='time'>
            {time.getHours()}:{time.getMinutes().toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })}
        </div>
    )
}