import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import { useWindows } from 'redux/windows';
import { useUser } from 'redux/user';


export const MessengerChatWindowContent = (props: { windowId: number }) => {
    const [writingInput, setWritingInput] = useState<string>('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messengerSocket = useUser().messengerSocket;
    const messengerToken = useUser().messengerToken;
    const window = useWindows().windows.find(window => window.id === props.windowId);

    const onSendClick = (event: React.MouseEvent) => {
        sendMessage();
    }

    const sendMessage = () => {
        if (window !== undefined && window !== null) {
            if (messengerSocket !== null) {
                messengerSocket.send(JSON.stringify({
                    'action': 'send_chat_message',
                    'data': {
                        'friend_id': window?.id - 1000,
                        'message': writingInput
                    },
                    'token': messengerToken
                }));
                setWritingInput('');
            }
        }
    }

    const onWritingInputKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && writingInput !== '') {
            sendMessage();
        }
    }

    const onWritingInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWritingInput(event.target.value);
    }

    useEffect(() => {
        if (window?.id! > 0 && window?.attributes.isOpen && messagesEndRef.current !== null) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

            if (messengerSocket !== null) {
                messengerSocket.send(JSON.stringify({
                    'action': 'read_chat_messages',
                    'data': {
                        'friend_id': window?.id - 1000,
                    },
                    'token': messengerToken
                }));
                setWritingInput('');
            }
        }
    }, [messengerSocket, messengerToken, window?.id, window?.attributes.chatMessages, window?.attributes.isOpen]);

    return window !== undefined && window !== null && window.attributes.chatMessages !== undefined ? (
        <div
            className='messenger'
            style={{
                height: window.attributes.size.height - 25,
                width: window.attributes.size.width
            }}
        >
            <div
                className='chat'
                style={{
                    height: window.attributes.size.height - 70,
                    width: window.attributes.size.width - 20
                }}
            >
                <div
                    className='messages'
                    style={{
                        height: window.attributes.size.height - 75,
                        width: window.attributes.size.width - 32
                    }}
                >
                    {window.attributes.chatMessages.map(chatMessage =>
                        <div
                            className='message'
                            key={chatMessage.id}
                        >
                            <div className='author'>
                                {chatMessage.author.username}:
                            </div>
                            <div className='text'>
                                {chatMessage.content}
                            </div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
            </div>
            <div className='writing'>
                <InputGroup>
                    <FormControl
                        onChange={onWritingInputChange}
                        onKeyPress={onWritingInputKeyPress}
                        value={writingInput}
                    />
                    <Button onClick={onSendClick} size='sm'>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                </InputGroup>
            </div>
        </div>
    ) : null;
}