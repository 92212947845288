import { createReduxModule } from 'hooks-for-redux';
import { MessengerFriend } from 'interfaces/MessengerFriend';


interface UserState {
	messengerFriends: Array<MessengerFriend>,
	messengerSocket: WebSocket | null,
	messengerToken: string,
	username: string
}

const initialState: UserState = {
	messengerFriends: [],
	messengerSocket: null,
	messengerToken: '',
	username: ''
}


export const [useUser, { addMessengerFriend, removeMessengerFriend, setMessengerFriends, setMessengerSocket, setMessengerToken, setUsername }] = createReduxModule(
	'user',
	initialState,
	{
		addMessengerFriend: (state, friend) => {
			const friendIndex = state.messengerFriends.findIndex(f => f.friend.id === friend.friend.id);

			return friendIndex === -1 ? {
				...state, messengerFriends: [...state.messengerFriends, friend]
			} : { ...state }
		},
		removeMessengerFriend: (state, friend) => {
			const friendIndex = state.messengerFriends.findIndex(f => f.friend.id === friend.friend.id);

			return {
				...state, messengerFriends: [...state.messengerFriends.slice(0, friendIndex), ...state.messengerFriends.slice(friendIndex + 1)]
			}
		},
		setMessengerFriends: (state, messengerFriends) => {
			return { ...state, messengerFriends: messengerFriends }
		},
		setMessengerSocket: (state, messengerSocket) => {
			return { ...state, messengerSocket: messengerSocket }
		},
		setMessengerToken: (state, messengerToken) => {
			return { ...state, messengerToken: messengerToken }
		},
		setUsername: (state, username) => {
			return { ...state, username: username }
		}
	}
)