import axios from 'axios';

import { ConsoleCommand } from '../interfaces/ConsoleCommand';
import { StartupMessage } from '../interfaces/StartupMessage';

import { settings } from '../settings';


export const getConsoleCommand = async (command: string): Promise<ConsoleCommand> => {
    let url = `${settings.API_BASE_URL}/computer/console?command=${command}`;

    const response = await axios.get(url);

    return response.data;
}


export const getStartupMessages = async (language: string): Promise<Array<StartupMessage>> => {
    let url = `${settings.API_BASE_URL}/computer/startup?language=${language}`;

    const response = await axios.get(url);

    return response.data;
}